<template>
  <div class="add-funds-wrap" id="add-funds-wrap">
    <NavBack/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding pt-unset-embedded-in-ios">
      <div class="page-title has-text-weight-semibold has-text-centered mb-3">Add funds via Bank transfer</div>
      <div class="main has-background-white">
        <div class="section-title has-text-centered px-2" id="anchor">Move some funds into your addy wallet to easily invest in new opportunities! 👌</div>
        <div class="label-of-input has-text-weight-semibold pl-3">Deposit from:</div>
        <b-select class="account-list" v-model="selectedAccount" expanded>
          <option
            v-for="account in accounts"
            :value="account.hashid"
            :key="account.hashid">
            {{ account.accountName }} ****{{account.accountNumberLast4}}
          </option>
        </b-select>
        <div class="label-of-input has-text-weight-semibold pl-3">Deposit amount:</div>
        <InputInTwoDecimals
          scrollAnchorId="anchor"
          pageId="add-funds-wrap"
          @onInput="onInput"
        ></InputInTwoDecimals>
        <div class="mt-2 has-text-centered">
          <span class="remaining-instant-amount has-text-lavender">⚡️ Instant available funds: ${{remainingEftInstantAmount}} remaining</span>
          <img src="@assets/icons/info.svg" alt="hint" class="hint-for-instant-funds is-clickable" @click="showInstantFundsModal = true">
        </div>
      </div>
      <BottomButtons
        nextButtonText="Add Funds"
        backButtonText="Back"
        :nextDisabled="amount <= 0"
        :loading="nextButtonIsLoading"
        :showSteps="false"
        @goNext="addFunds"
        @goBack="goBack"
      ></BottomButtons>
    </div>
    <div :class="`modal instant-funds-modal px-4 ${showInstantFundsModal ? 'is-active' : ''}`">
      <div class="modal-background is-clickable" @click="closeInstantFundsModal"></div>
      <div class="modal-content has-background-white">
        <div class="modal-header has-text-centered">Instant Funds ⚡️</div>
        <div class="modal-text">
          This is a short-term credit until your EFT (electronic funds transfer) is processed by your financial institution.
        </div>
        <div class="modal-text">
          {{modalText}}
          👌 If you deposit over your instant fund limit, the rest of your funds will be available in your addy wallet after your bank processes the transaction.
        </div>
        <b-button type="is-blue button-ok" class="has-text-weight-bold" @click="closeInstantFundsModal" expanded>Gotcha</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import InputInTwoDecimals from '@components/input/currency-input.vue'
import { getConnectedAccounts, getWalletDetails, addFundsToWallet } from '@api/wallet'
import axios from '@lib/axios/middleware'
import { formatter } from '@utils/common-methods/numberFormatter'

export default {
  components: {
    NavBack,
    MenuAndLogo,
    BottomButtons,
    InputInTwoDecimals,
  },
  data() {
    return {
      selectedAccount: '',
      accounts: [],
      eftInstantAmountLimit: 500,
      remainingEftInstantAmount: 0,
      membershipType: undefined,
      isApprovedAccredited: false,
      amount: 0,
      showInstantFundsModal: false,
      nextButtonIsLoading: false,
    }
  },
  computed: {
    modalText() {
      let text = `Your $${this.eftInstantAmountLimit} limit resets after your deposit is processed.`
      const suffix = `you have a $${this.eftInstantAmountLimit} limit instead of $500. The $${this.eftInstantAmountLimit} limit resets after your deposit is processed.`
      if (this.membershipType === 'Accredited Member') {
        text = this.isApprovedAccredited ? `As an Accredited Member, ${suffix}` : `While your Accredited application is pending, ${suffix}`
      }
      return text
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios.all([
        getConnectedAccounts(),
        getWalletDetails(),
      ]).then((data) => {
        if (data[0].success) this.handleAccountList(data[0].data.accountList)
        if (data[1].success) this.handleWalletDetails(data[1].data)
      })
    },
    handleAccountList(accountList) {
      this.accounts = accountList
      this.selectedAccount = accountList[0] ? accountList[0].hashid : ''
    },
    handleWalletDetails({ eftInstantAmountLimit, remainingEftInstantAmount, membershipType, approvedAccreditedInvestor }) {
      this.eftInstantAmountLimit = formatter(Number(eftInstantAmountLimit), 0)
      this.remainingEftInstantAmount = remainingEftInstantAmount
      this.membershipType = membershipType
      this.isApprovedAccredited = approvedAccreditedInvestor
    },
    onInput(value) {
      this.amount = value
    },
    addFunds() {
      if (!this.amount || !this.selectedAccount) return
      this.nextButtonIsLoading = true
      addFundsToWallet(this.amount, this.selectedAccount, 'eft').then((result) => {
        if (!result.success) return
        this.$router.push('/wallet/fundsSuccess')
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    closeInstantFundsModal() {
      this.showInstantFundsModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.add-funds-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  padding-bottom: 30px;
  .has-padding {
   padding: 18px 25px 0;
    .page-title {
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      font-size: 16px;
      border-radius: 12px;
      box-shadow: 0px 30px 50px rgba(215, 145, 51, 0.11);
      margin-bottom: 30px;
      padding: 22px 14px;
      .section-title {
        line-height: 20px;
        padding-bottom: 28px;
      }
      .label-of-input {
        font-size: 16px;
        margin-top: 10px;
        padding-bottom: 12px;
      }
      .remaining-instant-amount {
        font-size: 14px;
        padding-right: 8px;
      }
      .hint-for-instant-funds {
        vertical-align: middle;
      }
    }
  }
  .instant-funds-modal {
    .modal-content {
      max-width: $mobile-wrapper-width;
      position: relative;
      border-radius: 12px;
      padding: 24px 28px 24px;
      .modal-header {
        font-weight: 600;
        font-size: 28px;
        padding-bottom: 20px;
      }
      .modal-text {
        font-size: 16px;
        padding-bottom: 30px;
        line-height: 20px;
      }
      .button-ok {
        font-size: 16px;
        height: 43px;
        border-radius: 12px;
      }
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .add-funds-wrap {
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
<style scoped>
.account-list >>> .select,
.account-list >>> select {
  height: 51px;
}
.account-list >>> select {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.account-list >>> .select::after {
  border-color: #4A26AA;
  border-width: 2px;
}
</style>
